import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';

// To reduce bundle size, import only the components and icons you need
// https://github.com/warp-ds/elements?tab=readme-ov-file#import-components
// https://warp-ds.github.io/tech-docs/components/icons/
import createHeartComponent from '@finn-no/favorite-heart-component';
import pulse from '@finn-no/pulse-sdk';
import { i18n } from '@lingui/core';
import { AdvertisingSlotComponent } from '@schibsted-nmp/advertising-companion';
import '@warp-ds/elements/components/button';
import '@warp-ds/elements/components/card';
import '@warp-ds/icons/elements/shipping-16';
import { customElement } from 'lit/decorators/custom-element.js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecommendationItem, Recommendations } from '../../server/types.js';

@customElement('frontpage-recommendations')
export class FrontpageRecommendations extends WarpElement {
	static styles = [
		...WarpElement.styles, // TODO: why doesn't pick up correct brand colours?
		css`
			.custom-label-style {
				background: linear-gradient(
					transparent,
					rgba(0, 0, 0, 0.04935) 13.71%,
					/* ... (rest of the gradient styles) */ rgba(0, 0, 0, 0.78731) 89.73%,
					rgba(0, 0, 0, 0.8)
				);
				width: 100%;
			}
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			@css-placeholder-aX5IFq;
		`,
	];

	data!: Recommendations;
	newItems!: RecommendationItem[];
	url!: string;
	imgCdnUrl!: string;
	favoriteBaseUrl!: string;
	brand!: string;
	loginId!: string;
	nextPage = 0;
	canFetchMore = true;
	throttleTimer: boolean | undefined;
	hasBottomReachedBeenTracked = false;

	private mountedAds = new Set<string>();
	private pendingAds = new Set<string>();

	static properties = {
		data: { type: Object, default: {} },
		imgCdnUrl: { type: String },
		favoriteBaseUrl: { type: String },
		newItems: { type: Object, default: [], attribute: false },
		url: { type: String },
		brand: { type: String },
		loginId: { type: String },
		canFetchMore: { type: Boolean, attribute: false, state: true },
		nextPage: { type: Number, attribute: false, default: 0 },
	};

	wcRef =
		typeof document !== 'undefined'
			? document.querySelector('frontpage-recommendations')?.shadowRoot?.getElementById('recommendations')
			: undefined;

	connectedCallback() {
		super.connectedCallback();

		const locale = this.locale || 'en';
		const messages = JSON.parse(this.getAttribute('translations') || '{}');

		i18n.load(locale, messages);
		i18n.activate(locale);

		// Insert Oikotie Ad in the specified location
		const oikotieAd = document.querySelector('frontpage-recommendations')?.shadowRoot?.querySelector('#oikotie-ad');
		oikotieAd?.insertAdjacentHTML(
			'afterbegin',
			`<iframe
							src="https://recommendations.asunnot.oikotie.fi/tori-aurora-front-page.html"
							class="relative isolate recommendation-ad card card--cardShadow s-bg"
							width="100%"
							height="100%"
						></iframe>`,
		);

		// Add infinite scroll event listener
		this.addInfiniteScroll();

		// Track view ad event
		this.trackViewEvent();
		window.addEventListener('scroll', this.trackViewEvent);

		// Track bottom reach
		window.addEventListener('scroll', this.handleBottomReach);
	}

	/** Utility functions **/

	isItemCMS(item: RecommendationItem) {
		return item.type === 'CMS';
	}

	isItemAdvertising(item: RecommendationItem) {
		return item.type === 'NATIVE' || item.type === 'BANNER';
	}

	isInViewport = (el: Element) => {
		const { top, bottom } = el.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;

		const elementHeight = bottom - top;
		const halfHeight = elementHeight / 2;

		const topInView = top >= 0 && top < windowHeight;
		const bottomInView = bottom > 0 && bottom <= windowHeight;

		return (topInView && top + halfHeight <= windowHeight) || (bottomInView && bottom - halfHeight >= 0);
	};

	favoriteHeart = (item: RecommendationItem) => {
		const Heart = createHeartComponent({
			baseUri: this.favoriteBaseUrl,
			renderServerSide: true,
			userId: this.loginId,
			modalAttach: this.wcRef as HTMLDivElement,
		});

		setTimeout(() => {
			const heartRef = this.isItemCMS(item) ? null : this.shadowRoot?.querySelector(`#heart-${item.itemId}`);
			if (heartRef) {
				const root = createRoot(heartRef);
				root.render(
					<Heart
						variant="heart-on-image"
						itemType="Ad"
						locale={this.locale as 'en' | 'nb' | 'da' | 'fi'}
						itemId={parseInt(item.itemId)}
						isMobile={false}
						renderNumFavs={false}
					/>,
				);
			}
		}, 100);
	};

	// Infinite scroll & fetch more
	addInfiniteScroll = () => {
		window.addEventListener('scroll', this.handleInfiniteScroll);
	};
	throttle = (callback: { (): void; (): void }, time: number | undefined) => {
		if (this.throttleTimer) return;

		this.throttleTimer = true;

		setTimeout(() => {
			callback();
			this.throttleTimer = false;
		}, time);
	};
	handleInfiniteScroll = () => {
		this.throttle(() => {
			const endOfPage =
				window.innerHeight + window.scrollY >=
				(this.shadowRoot?.getElementById('recommendations')?.offsetHeight || document.body.offsetHeight);

			if (endOfPage) {
				this.fetchMore();
			}

			if (this.nextPage === 5 || !this.canFetchMore) {
				this.removeInfiniteScroll();
			}
		}, 200);
	};
	removeInfiniteScroll = () => {
		window.removeEventListener('scroll', this.handleInfiniteScroll);
	};

	fetchMore() {
		const url = new URL(`${this.url}/recommendations-proxy/${this.data?.fetchMore[this.nextPage]}`);

		if (this.canFetchMore) {
			try {
				fetch(url)
					.then((response) => response.json())
					.then((data) => {
						if (this.nextPage + 1 === 6) {
							this.addInfiniteScroll();
						}
						this.nextPage = this.nextPage + 1;
						this.canFetchMore = this.nextPage !== this.data?.fetchMore.length;
						this.newItems = data.items;
						this.data.items.push(...this.newItems);
					});
			} catch (e) {
				console.log(e);
			}
		}
	}
	// TODO: rename it
	_clickedMe() {
		this.fetchMore();
	}

	// Tracking
	trackViewEvent = () => {
		const items = this.data?.items;
		if (items) {
			items.forEach((item) => {
				const element = this.isItemAdvertising(item)
					? this.shadowRoot?.getElementById(item.itemId.replace(/:/g, '-'))
					: this.shadowRoot?.getElementById(`ad-${item.itemId}`);

				if (element && this.isInViewport(element) && !item.hasBeenViewed && item.tracking?.pulse) {
					item.hasBeenViewed = true;
					const trackingData = item.tracking?.pulse?.recommendation?.filter((event) => event.type === 'View')[0];

					pulse.trackEvent(
						{
							type: trackingData?.type,
							name: trackingData?.name,
							object: trackingData?.object,
							recommendation: this.data?.['tracking-context']?.recommendation,
						},
						{
							disableAddClassifiedAdToItems: true,
						},
					);
				}
			});
		}
	};
	_trackClickEvent(item: RecommendationItem) {
		if (item.tracking?.pulse) {
			const trackingData = item.tracking.pulse.recommendation.filter((event) => event.type === 'Click')[0];

			pulse.trackEvent(
				{
					intent: trackingData.intent,
					name: trackingData.name,
					object: trackingData.object,
					target: trackingData.target,
					type: trackingData.type,
					recommendation: this.data['tracking-context']?.recommendation,
				},
				{
					disableAddClassifiedAdToItems: true,
				},
			);
		}
	}

	handleBottomReach = () => {
		const bottom = document.querySelector('frontpage-recommendations')?.shadowRoot?.getElementById('bottom');
		if (bottom) {
			const reached = bottom.getBoundingClientRect().top <= window.innerHeight;

			if (reached && !this.canFetchMore && !this.hasBottomReachedBeenTracked) {
				this.hasBottomReachedBeenTracked = true;
				fetch(`${this.url}/tracking`);
			}
		}
	};

	// Show/hide elements
	isEmptyObject(obj: object): boolean {
		return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
	}
	showHistoryLink = () => {
		return this.data?.items.length > 0 && this.brand?.toUpperCase() === 'FINN' && this.data?.isPersonal;
	};
	showFetchMoreButton = () => {
		return this.nextPage === 5 && this.canFetchMore;
	};

	/** Rendering functions **/

	label = (item: RecommendationItem) => {
		if (item.shippingOption?.label) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-warning-subtle-hover"
			>
				<w-icon-shipping-16 class="mr-4"></w-icon-shipping-16>
				${item.shippingOption.label}
			</span>`;
		}

		if (item.overlay?.easyapply?.label) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-positive-subtle-hover s-text-rgb-positive-active"
			>
				${item.overlay?.easyapply.label}
			</span>`;
		}

		if (item.overlay?.p4p?.label) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active"
			>
				${item.overlay.p4p.label}
			</span>`;
		}

		if (this.isItemCMS(item)) {
			return html`<span
				class="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active"
			>
				${i18n.t({
					id: 'frontpage.ads.cms-article.badge.label',
					message: 'Useful article',
				})}
			</span>`;
		}
	};

	image = (item: RecommendationItem) => {
		const isLogo = item.image?.type === 'LOGO';
		const imageClass = isLogo
			? 'w-full h-full object-center object-scale-down m-auto sm:h-auto'
			: 'w-full h-full object-center object-cover m-auto';
		const imageSrc =
			item.image?.isAbsolute || item.image?.type === 'EXTERNAL'
				? item.image.url
				: `${this.imgCdnUrl}/${item.image?.url}`;

		return html`<div class="aspect-square overflow-hidden rounded-8 border s-bg hover:s-bg-hover active:s-bg-active">
			<img
				class="${imageClass}"
				src="${imageSrc}"
				alt="${i18n.t({ id: 'frontpage.recommendations.images.alt.text', message: 'Photo from ad' })}"
			/>
			${item.type?.toUpperCase() !== 'JOB'
				? html`<span>
						<div class="absolute bottom-0 font-bold s-text-inverted-subtle py-10 pl-10 pr-16 custom-label-style">
							${item.label}
						</div>
					</span>`
				: ''}
		</div>`;
	};

	recommendationItem = (item: RecommendationItem) => {
		this.favoriteHeart(item);

		const href = this.isItemCMS(item) ? item.itemId : `/${item.itemId}`;
		if (item.type === 'oikotie') {
			return this.oikotieAd();
		} else {
			return html`
				<w-card id=${`ad-${item.itemId}`}>
					${item.image ? this.image(item) : ''}
					<div class="text-s s-text-subtle mx-16 mt-8">
						<span>${item.location?.combined}</span>
					</div>
					<div class="mx-16 mt-6 mb-14">
						<h2 class="t4 mb-0 break-words" id=${item.itemId}>
							<a
								class="s-text hover:no-underline line-clamp"
								href=${href}
								id=${item.itemId}
								@click="${() => this._trackClickEvent(item)}"
							>
								<span class="absolute inset-0" aria-hidden="true"></span>
								${item.heading}
							</a>
						</h2>
						<div class="text-s s-text-subtle mt-8">
							${item.content?.company_name ? html`<div>${item.content?.company_name}</div>` : ''}
							${item.customLabel ? html`<div>${item.customLabel}</div>` : ''}
						</div>
					</div>
					${this.isItemCMS(item) ? '' : html`<div class="absolute top-8 right-8" id=${`heart-${item.itemId}`}></div>`}
					${this.label(item)}
				</w-card>
			`;
		}
	};

	advertisingItem = (item: RecommendationItem) => {
		const divId = item.itemId.replace(/:/g, '-');

		// Add to pending if not already mounted
		if (!this.mountedAds.has(divId)) {
			this.pendingAds.add(divId);
		}

		return html`<div id="${divId}"></div>`;
	};

	protected updated(changedProperties: Map<string, unknown>): void {
		super.updated(changedProperties);

		// Mount any pending ads
		for (const divId of this.pendingAds) {
			const advertisingAdRef = this.shadowRoot?.querySelector(`#${CSS.escape(divId)}`);
			if (advertisingAdRef) {
				const item = this.data?.items.find((item) => item.itemId.replace(/:/g, '-') === divId);
				if (!item) continue;

				const root = createRoot(advertisingAdRef);
				const categories = {
					main_category: item.content?.main_category,
					sub_category: item.content?.sub_category,
					prod_category: item.content?.prod_category,
				};

				root.render(
					<AdvertisingSlotComponent
						/* We need to pass a matching id as the parent element to "hide" the ad properly if no ads should show */
						itemId={divId}
						brand={this.brand}
						categories={JSON.stringify(categories)}
						initialLayoutType={'grid'}
						webComponentName={'frontpage-recommendations'}
					/>,
				);

				this.mountedAds.add(divId);
				this.pendingAds.delete(divId);
			}
		}
	}

	recommendationsTitle = () => {
		return this.data?.isPersonal
			? i18n.t({
					id: 'frontpage.recommendations.section.title',
					message: 'Personalized recommendations',
				})
			: i18n.t({
					id: 'frontpage.popular.section.title',
					message: 'Popular ads',
				});
	};

	oikotieAd = () => {
		return html`<div
			class="cursor-pointer overflow-hidden relative transition-all group rounded-8 s-surface-elevated-200 hover:s-surface-elevated-200-hover active:s-surface-elevated-200-active  s-bg hover:s-bg-hover active:s-bg-active s-border hover:s-border-hover active:s-border-active"
			id="oikotie-ad"
		></div>`;
	};

	render() {
		const isDataEmpty = !this.data || this.isEmptyObject(this.data.items);
		return html`
			<div id="recommendations">
				<section>
					<div class="flex flex-row items-center my-16">
						<h1 class="text-ml mb-4">${this.recommendationsTitle()}</h1>
						${this.showHistoryLink()
							? html`<a href="/anbefalinger/historikk" class="link text-14 px-8">
									${i18n.t({
										id: 'frontpage.recommendations.history.link.text',
										message: 'Why we recommend these ads?',
									})}
								</a>`
							: ''}
					</div>
					<div class="grid grid-cols-2 md:grid-cols-3 gap-10 mb-16 grid-flow-row-dense">
						${!isDataEmpty
							? this.data?.items?.map((item) =>
									this.isItemAdvertising(item) ? this.advertisingItem(item) : this.recommendationItem(item),
								)
							: ''}
					</div>
					${this.showFetchMoreButton()
						? html`
								<div class="text-center mb-16">
									<w-button variant="primary" @click="${this._clickedMe}"
										>${i18n.t({
											id: 'frontpage.recommendations.fetch-more-button.label',
											message: 'See more recommendations',
										})}</w-button
									>
								</div>
							`
						: ''}
					<span
						class="hidden col-span-5 sm:col-span-3 focus:[--w-outline-offset:-2px] sm:col-span-2 md:grid-cols-2 gap-16 flex-row object-cover text-left text-ellipsis line-clamp-2 mt-24 pb-24 lt-sm:min-w-full"
						id="bottom"
					></span>
				</section>
			</div>
		`;
	}
}
